import Box from "../Atomic/Box"

import React from "react"
import ContactInfo from "./ContactInfo"

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        paddingX: "sm",
        // backgroundColor: "greyScale.1",
        backgroundColor: "lightBackground",
        marginTop: "sm",
      }}
    >
      <ContactInfo />
    </Box>
  )
}
