import * as React from "react"

function SvgEmail(props) {
  return (
    <svg width={14} height={10} fill="none" {...props}>
      <path
        d="M6.836 6.3L7 6.442l.164-.144L13.75.55v9.2H.25V.55L6.836 6.3zM.666.25h12.668L7 5.78.667.25z"
        fill="#fff"
        stroke="#000"
        strokeWidth={0.5}
      />
    </svg>
  )
}

export default SvgEmail
