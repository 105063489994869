import Flex from "../Atomic/Flex"
import Box from "../Atomic/Box"
import { Email } from "../../icons"

import React from "react"
import format from "date-fns/format"

export default function ContactInfo() {
  const email = `andreeastroem@gmail.com`
  const today = format(new Date(), "yyyy")
  const copyrightText = today !== "2020" ? `2020-${today}` : `2020`
  return (
    <Flex sx={{ justifyContent: "space-around", flexWrap: "wrap" }}>
      {/* Email */}
      <Box py={`sm`}>
        <Flex sx={{ marginRight: "sm", fontSize: "sm", alignItems: "center" }}>
          <Flex marginRight={"sm"}>
            <Email />
          </Flex>
          <Box
            sx={{ textAlign: "left", color: "white", textShadow: "neon" }}
            as={`a`}
            href={`mailto:${email}`}
          >
            {email}
          </Box>
        </Flex>
      </Box>
      {/* Copyright */}
      <Flex sx={{ fontSize: "sm", alignItems: "center" }}>
        <Box
          sx={{
            textAlign: "left",
            color: "white",
            textShadow: "neon",
            marginRight: "sm",
          }}
        >
          &copy; {copyrightText}
        </Box>
        <Box sx={{ textAlign: "left", color: "white", textShadow: "neon" }}>
          André Åström [kjof]
        </Box>
      </Flex>
    </Flex>
  )
}
