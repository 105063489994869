import Flex from "../Atomic/Flex"
import { Helmet } from "react-helmet"
import React from "react"

import Header from "../Header"
import Footer from "../Footer"

// import { useBreadcrumb } from "gatsby-plugin-breadcrumb"

export default function PageStructure({
  children,
  metaContent,
  pageTitle,
  // location,
}) {
  // const { crumbs } = useBreadcrumb({
  //   location: location,
  //   crumbLabel: pageTitle,
  //   crumbSeparator: " * ",
  // })
  return (
    <Flex
      id={`pagestructure`}
      sx={{
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        // backgroundColor: "greyScale.6",
        backgroundColor: "background",
        minHeight: "100%",
        flexGrow: 1,
      }}
    >
      <Helmet>
        <meta name="Description" content={metaContent} />
        <title>{pageTitle}</title>
        <html lang="en" />
      </Helmet>
      <Header headerText={pageTitle.toUpperCase()} />
      {children}

      <Flex
        sx={{
          height: "100%",
          flexGrow: 1,
          justifyContent: "flex-end",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Footer />
      </Flex>
    </Flex>
  )
}
